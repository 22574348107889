import React from 'react'
import '@toast-ui/editor/dist/toastui-editor.css';
import { Viewer } from '@toast-ui/react-editor';
import styled from 'styled-components';

interface toastViewerItemType {
    content:string;
}

export default function ToastViewerItem({content}:toastViewerItemType) {
    return (
        <Wrap>
            {content && (
                <Viewer
                    initialValue = {content || ''}
                ></Viewer>
            )}
        </Wrap>
    )
}

const Wrap = styled.div`
    & *{
        color: #fff !important;
    }
`;