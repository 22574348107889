import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_ROUTER_HISTORY = 'mesh-dash/common/SET_ROUTER_HISTORY';

export const setHistoryActionCreator = createAction(SET_ROUTER_HISTORY);

export function setGlobalHistory(url: any): ThunkAction<void, RootState, null, ReturnType<typeof setHistoryActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setHistoryActionCreator({
              url: url
            }));
    } catch (error) {
      
    }
  };
}

export interface RouterState {
    history?: string;
}

const initialState: RouterState = {
    history: '/'
};

export const globalHistory = handleActions<RouterState, any>({
  [SET_ROUTER_HISTORY]: (state, action: ReturnType<typeof setHistoryActionCreator>) => ({
    history: action.payload.url ?? state.history
  }),
}, initialState);
