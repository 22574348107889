import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import BgMoreBtnItem from '../item/BgMoreBtnItem';
import { apiGetNoticeList } from '../../../api/api';
import { NOTICE_ITEM } from '../../../api/api';
import { momentFormat } from '../../../utils/format/time';

export default function NoticePart() {
    const [list,setList] = useState([NOTICE_ITEM]);
    const [total,setTotal] = useState(0);
    const [nowPage, setNowPage] = useState(1);
    const navigate = useNavigate();
    const goNewsPage = () =>{
        if(total === nowPage) return;
        setNowPage(page=>page+1);
    }

    useEffect(()=>{
        // getList();
    },[nowPage])

    const getList = async() => {
        const res = await apiGetNoticeList(nowPage,5);
        
        if(res.result){
            if(nowPage === 1){
                setList(res.data);
            }else{
                setList(data=>[...data,...res.data]);
            }
            setTotal(res.total);
            return;
        }
        setList([NOTICE_ITEM]);
        setTotal(res.total);
    }
    
    return (
        <Wrap>
            <MaxWidthWrap>
                <NoticeListWrap>
                    {/* <NoticeItemWrap to={'/newsDetailPage/1'}>
                        <BasicText>SUWORLD2030 프로젝트 공개</BasicText>
                        <BasicText>2023-02-17</BasicText>
                    </NoticeItemWrap> */}
                    {(list.length === 0 || list[0].notice_id === 0) ?
                        <Center>
                            There are no notice.
                        </Center> :
                        list.map((data,index)=>{
                            return(
                                <NoticeItemWrap to={`/newsDetailPage/${data.notice_id}`} key={`notice-${index}`}>
                                    <BasicText>{data.notice_title}</BasicText>
                                    <BasicText>{momentFormat('YYYY-MM-DD',data.notice_time) }</BasicText>
                                </NoticeItemWrap>

                            )
                        })

                    }
                </NoticeListWrap>
            </MaxWidthWrap>
            {(total > 1 && !(total === nowPage)) ?
                <BtnWrap>
                    <BgMoreBtnItem clickEvent={goNewsPage}></BgMoreBtnItem>
                </BtnWrap> :
                <></>     
            }
        </Wrap>
    )
}

const Wrap = styled.div``;
const NoticeListWrap = styled.div`
    margin-top: 10px;
`;
const NoticeItemWrap = styled(Link)`
    display: block;
    border-bottom: 1px solid #fff;
    text-align: center;
    padding: 20px 0;
    &:nth-last-of-type(1){border-bottom:none; padding-bottom:0;}
    & p{
        display: block;
    }
    & p:nth-of-type(1){
        font-size: 32px;
        font-weight: 800;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    & p:nth-of-type(2){
        font-size: 18px;
    }
    @media ${({theme}:any)=>theme.media.m}{
        & p:nth-of-type(1){
            font-size: 24px;
        }
    }
`;
const BtnWrap = styled(Center)`
    margin-top: 20px;
`;