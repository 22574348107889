import { cyan, grey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import thunk from 'redux-thunk';
import GlobalLoadingModalPage from './components/common/page/GlobalLoadingModalPage';
import Router from './routes/Router';
import reducer from './store/store';
import { addTheme } from './style/basic/addTheme';
import { GlobalStyle } from './style/globalStyle';

export default function App(){

  const myReducer = reducer();
  const store = createStore(myReducer, applyMiddleware(thunk));
  const persistor = persistStore(store);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // const store = createStore(rootReducer);


  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'dark',
          primary: {
            main: cyan[500],
          },
          secondary: {
            main: grey[100],
          }
        },
        typography: {
          fontFamily: "'ChakraPetch-Regular', sans-serif"
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: 'rgba(0, 223, 255, 0.3)',
                border: '1px solid rgba(0, 223, 255, 1.0)'
              }
            }
          }
        }
      }),
    [prefersDarkMode],
  );
  const newTheme = {
    ...theme,
    ...addTheme
  }
  return (
    <>
    {/* <StrictMode> */}
        <ThemeProvider theme={newTheme}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <GlobalStyle/>
                <Router/>
                <GlobalLoadingModalPage/>
            </PersistGate>
          </Provider>
        </ThemeProvider>
    {/* </StrictMode> */}
    </>
  )
}