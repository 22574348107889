import styled from '@emotion/styled';
import React from 'react'
import { Center } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

export default function TotalTitlePart() {
    return (
        <Wrap>
            <LogoWrap>
                <img alt='logo' src='./img/link/logo.png'></img>
            </LogoWrap>
            <BasicText fontWeight={800} fontSize={22}>SUWORLD</BasicText>
            <BasicText fontSize={20}>LET'S PLAY TOGETHER</BasicText>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    flex-direction: column;
`;
const LogoWrap = styled(Center)`
    width: 75px;
    height: 75px;
    border-radius: 100%;
    overflow: hidden;
    & img{
        width: 100%;
    }
`;
