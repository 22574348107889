import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_INIT_SECURITY_LOG_DATA = 'mesh-dash/security/SET_INIT_SECURITY_LOG_DATA';
const SET_SECURITY_LOG_DATA = 'mesh-dash/security/SET_SECURITY_LOG_DATA';

export const setInitSecurityLogActionCreator = createAction<any>(SET_INIT_SECURITY_LOG_DATA);
export const setSecurityLogActionCreator = createAction<any>(SET_SECURITY_LOG_DATA);

export function setInitSecurityLogData(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setInitSecurityLogActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setInitSecurityLogActionCreator({
                data
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setSecurityLogData(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setSecurityLogActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setSecurityLogActionCreator({
                data: data
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

let log_id_number: number = 6;

const initialState: any = {
    log_data: [
        {
            log_id: log_id_number,
            log_ip: '192.168.1.106',
            log_warning: true,
            log_timestamp: 1657078649000,
            animate: true
        },
        {
            log_id: log_id_number - 1,
            log_ip: '14.45.4.5',
            log_warning: true,
            log_timestamp: 1657078649000,
            animate: true
        },
        {
            log_id: log_id_number - 2,
            log_ip: '11.88.41.6',
            log_warning: true,
            log_timestamp: 1657078649000,
            animate: true
        },
        {
            log_id: log_id_number - 3,
            log_ip: '20.9.107.189',
            log_warning: true,
            log_timestamp: 1657078649000,
            animate: true
        },
        {
            log_id: log_id_number - 4,
            log_ip: '82.66.41.271',
            log_warning: true,
            log_timestamp: 1657078649000,
            animate: true
        },
        {
          log_id: log_id_number - 5,
          log_ip: '3.8.73.112',
          log_warning: true,
          log_timestamp: 1657078649000,
          animate: true
      },
    ]
};

export const securityLog = handleActions<any, any>({
  [SET_INIT_SECURITY_LOG_DATA]: (state, action: ReturnType<typeof setInitSecurityLogActionCreator>) => ({
    log_data: action.payload.data
  }),
  [SET_SECURITY_LOG_DATA]: (state, action: ReturnType<typeof setSecurityLogActionCreator>) => {
    const prevBlockList = state.log_data;
    const socketData = action.payload.data;
    const result = [socketData, ...prevBlockList];
    return {
        log_data: result.slice(0, 6)
    }
  }
}, initialState);
