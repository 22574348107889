import styled from '@emotion/styled';
import React from 'react'
import { Center, MaxWidthWrap, RowBetween, Row } from '../../../style/basic/commonStyle';
import { FOOTER_LINK } from '../../data/content';

export default function FooterPage({menuClick}:any) {
    return (
        <Wrap>
            <MaxWidthWrap>
                <FooterWrap>
                    <LogoWrap onClick={()=>menuClick(6)}>
                        <img alt='logo' src='./img/home/logo.png'></img>
                    </LogoWrap>
                    <IconWrap>
                        {FOOTER_LINK.map((data)=>{
                            return(
                                <IconItem href={data.link} target='_blank' key={`footer-link-${data.id}`}>
                                    <img alt='icon-img' src={data.img}></img>
                                </IconItem>
                            )
                        })}
                    </IconWrap>
                </FooterWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    min-height: 150px;
`;

const FooterWrap = styled(RowBetween)`
    width: 100%;
    min-height: 150px;
    flex-wrap: wrap;
`;
const LogoWrap = styled(Center)`
    height: 100%;
    @media ${({theme}:any)=>theme.media.m}{
        width: 100%;
        justify-content: start;
    }
    & img{
        max-height: 30px;
    }
`;
const IconWrap = styled.div`
    display: flex;
    @media ${({theme}:any)=>theme.media.m}{
        width: 100%;
    }
`;
const IconItem = styled.a`
    width: 42px;
    height: 42px;
    margin-right: 20px;
    & img{
        width: 100%;
    }
    &:nth-last-of-type(1){
        margin-right: 0;
    }
`;