import styled from '@emotion/styled';
import React, { useRef } from 'react'
import BannerPart from '../part/BannerPart';
import BlockChainPart from '../part/BlockChainPart';
import ContactUsPart from '../part/ContactUsPart';
import JoinSuperWorldPart from '../part/JoinSuperWorldPart';
import NoticePart from '../part/NoticePart';
import PlayTogetherPart from '../part/PlayTogetherPart';
import PlayZonePart from '../part/PlayZonePart';
import TitlePart from '../part/TitlePart';
import { Fade } from 'react-awesome-reveal';
import useLang from '../../../utils/custom-hooks/common/useLang';

export const COLORS = [
    ['rgba(1,251,229,1)','rgba(34,165,255,1)','rgba(172,0,255,1)'],
    ['rgba(255,240,2,1)','rgba(255,29,72,1)','rgba(255,1,221,1)'],
    ['rgba(194,247,255,1)','rgba(8,224,255,1)'],
]

interface mainPageType{
    refList:Array<any>;
}

export default function HomePage({refList}:mainPageType) {
    
    return (
        <Wrap ref={refList[5]}>
            <BannerPart></BannerPart>
            <BlockChainWrap ref={refList[0]}>
                <Fade direction='bottom-right' triggerOnce delay={500}>
                    <TitlePart 
                        colors={COLORS[0]} 
                        colorCount={3}
                        title='BlockChain' subTitle={useLang(0)} 
                        link='#' 
                        padding='80px 0 60px'
                    ></TitlePart>
                    <BlockChainPart></BlockChainPart>
                </Fade>
            </BlockChainWrap>
            <PlayZoneWrap ref={refList[1]}>
                <Fade direction='bottom-left' triggerOnce delay={500}>
                    <TitlePart 
                        colors={COLORS[1]} 
                        colorCount={3}
                        title='Play Zone'
                        subTitle='Create & Play with your super world' 
                        link='#'
                        padding='40px 0 60px'
                    ></TitlePart>
                    <PlayZonePart></PlayZonePart>
                </Fade>
            </PlayZoneWrap>
            <PlayTogether ref={refList[2]}>
                <Fade direction='bottom-right' triggerOnce delay={500}>
                    <TitlePart
                        colors={COLORS[0]}
                        colorCount={3}
                        type='center' 
                        title='Play Together'
                        subTitle="LET'S PLAY TOGETHER"
                        padding='40px 0 60px'
                    ></TitlePart>
                    <PlayTogetherPart></PlayTogetherPart>
                </Fade>
            </PlayTogether>
            <JoinSuperWorldWrap ref={refList[3]}>
                <Fade direction='bottom-left' triggerOnce delay={500}>
                    <TitlePart
                        colors={COLORS[1]}
                        colorCount={3}
                        type='center'
                        title='Join Super World'
                        subTitle="LET'S PLAY TOGETHER"
                        padding='40px 0 60px'
                    ></TitlePart>
                    <JoinSuperWorldPart></JoinSuperWorldPart>
                </Fade>
            </JoinSuperWorldWrap>
            <NoticeWrap ref={refList[4]}>
                <Fade direction='bottom-right' triggerOnce delay={500}>
                    <TitlePart
                        colors={COLORS[2]}
                        colorCount={2}
                        type='center'
                        title='NOTICE'
                        padding='40px 0 60px'
                    ></TitlePart>
                    <NoticePart></NoticePart>
                </Fade>
            </NoticeWrap>
            <ContactUsWrap>
                <ContactUsPart></ContactUsPart>    
            </ContactUsWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    height: 100%;
    overflow: hidden;
`;

const SectionStyle = styled.div`
    padding-top: 180px;
    @media ${({theme}:any)=>theme.media.m}{
        padding-top: 50px;
    }
`;

const BlockChainWrap = styled.div``;
const PlayZoneWrap = styled(SectionStyle)``;
const PlayTogether = styled(SectionStyle)``;
const JoinSuperWorldWrap = styled(SectionStyle)``;
const NoticeWrap = styled(SectionStyle)``;
const ContactUsWrap = styled.div`
    padding-top: 200px;
`;