import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import FooterPage from '../../common/page/FooterPage';
import HeaderPage from '../../common/page/HeaderPage';
import HomePage from '../../home/page/HomePage';
import NewsDetailPage from '../../news/page/NewsDetailPage';
import NewsPage from '../../news/page/NewsPage';
import ScrollTop from '../../../utils/custom-hooks/common/ScrollTop';
import ComingSoonPart from '../part/ComingSoonPart';

export default function MainPage(){
    const navigate = useNavigate();
    const top = useRef<HTMLDivElement>(null);
    const blockChain = useRef<HTMLDivElement>(null);
    const playZone = useRef<HTMLDivElement>(null);
    const together = useRef<HTMLDivElement>(null);
    const joinUs = useRef<HTMLDivElement>(null);
    const news = useRef<HTMLDivElement>(null);
    const refList = [blockChain,playZone,together,joinUs,news,top];

    const scrollMove = (el:any) =>{
        el.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        return;
    }

    const menuClick = (id:number) =>{
        navigate('/');
        setTimeout(()=>{
            scrollMove(refList[(id-1)]);
        },200)
    }
    
    return (
        <Wrap>
            <ComingSoonPart></ComingSoonPart>
            <ScrollTop></ScrollTop>
            <TopMenuWrap>
                <HeaderPage menuClick={menuClick}></HeaderPage>
            </TopMenuWrap>
            <ContentWrap>
                <Routes>
                    <Route path='/' element={<HomePage refList={refList}></HomePage>}></Route>
                    <Route path='/newsPage' element={<NewsPage></NewsPage>}></Route>
                    <Route path='/newsDetailPage/:id' element={<NewsDetailPage></NewsDetailPage>}></Route>
                </Routes>
            </ContentWrap>
            <BottomMenuWrap>
                <FooterPage menuClick={menuClick}></FooterPage>
            </BottomMenuWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    background-color: #000;
    color: #fff;
`;

const TopMenuWrap = styled.div`
    height: ${({theme}:any)=>theme.headerHeight};
    width: 100%;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    background-color: #000;
    z-index: 50;
`;

const ContentWrap = styled.div`
    margin-top:${({theme}:any) => theme.headerHeight};
`;

const BottomMenuWrap = styled.div``;