export const TEXT_DATA = [
    {
        // 0
        ko:`지금 슈월드는 WEB 3.0 기반의 최신 기술을 이끌어가고 있습니다.`,
        en:`SUWORLD is now leading the latest technology based on WEB 3.0.`,
    },
    {
        // 1
        ko:[
            'Web3.0 기반의 빠르고 안전한<br> 컨소시움형 블록체인을 이용할 수 있습니다.',
            '당신의 비즈니스에 다양한 블록체인<br> 솔루션을 적용해보세요.',
            '블록체인 지갑 기반 금융 시스템으로 빠르고<br> 안전한 금융 서비스를 간편하게 구축할 수 있습니다.',
            '슈월드 패밀리가 되어<br> 다양한 서비스를 이용하세요.',
        ],
        en:[
            'Fast and secure <br> consortium blockchain based on Web3.0',
            'Apply various blockchain solutions to your business.',
            'Blockchain wallet-based financial systems make it easy to build fast and secure financial services.',
            'Become a SUWORLD family and use various services.'
        ],
    },
]
