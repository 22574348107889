import { GAME_LINK, NEW_MUSIC_LINK, SNS_LINK, TWITTER_LINK } from "./linkData";

export const BLOCK_CHAIN_CONTENT =[
    {id:1,img:'./img/mtc.png',title:'Mesh Triple Chain',desc:'Web3.0 기반의 빠르고 안전한<br> 컨소시움형 블록체인을 이용할 수 있습니다.',link:'https://testscan.meshtriple.com',more:'https://meshtriple.com/'},
    {id:2,img:'./img/mtc.png',title:'Blockchain Cloud Service',desc:'당신의 비즈니스에 다양한 블록체인<br> 솔루션을 적용해보세요.',link:'#',more:'#'},
    {id:3,img:'./img/mtc.png',title:'Blockchain Pay',desc:'블록체인 지갑 기반 금융 시스템으로 빠르고<br> 안전한 금융 서비스를 간편하게 구축할 수 있습니다.',link:'#',more:'#'},
    {id:4,img:'./img/mtc.png',title:'SUWORLD NFT',desc:'슈월드 패밀리가 되어<br> 다양한 서비스를 이용하세요.',link:'#',more:'#'},
];

export const BLOCK_CHAIN_IMG = [
    {id:1,img:'./img/home/mtc.png'},
    {id:2,img:'./img/home/bcs.png'},
    {id:3,img:'./img/home/pay.png'},
    {id:4,img:'./img/home/nft.png'},
]

export const BLOCK_CHAIN_IMG_COLOR = [
    {id:1,color1:'#37acff',color2:'#5000ff'},
    {id:2,color1:'#d9dce2',color2:'#cf9847'},
    {id:3,color1:'#a2dcf1',color2:'#5ce95e'},
    {id:4,color1:'#f0efef',color2:'#393837'},
]

export const PLAY_ZONE_LIST = [
    {id:1,name:'game',img:'./img/home/play1.png',img_en:'./img/home/play1_en.png', link:'https://play.google.com/store/apps/details?id=com.mtc.sugame'},
    {id:2,name:'music',img:'./img/home/play2.png',img_en:'./img/home/play2_en.png',link:'https://play.google.com/store/apps/details?id=com.subox.player'},
    {id:3,name:'pay',img:'./img/home/play3.png',img_en:'./img/home/play3_en.png',link:'#'},
    {id:4,name:'trip',img:'./img/home/play4.png',img_en:'./img/home/play4_en.png',link:'https://portrip.net/'},
];

export const PLAY_TOGETHER_CONTENT = [
    {id:1,img:'',text:'SUBOX'},
    {id:2,img:'',text:'CRYPTOPOWER'},
    {id:3,img:'',text:'GODER'},
    {id:4,img:'',text:'SmileYoungLABS'},
    {id:5,img:'',text:'NVP'},
    {id:6,img:'',text:'NTechTown'},
];

export const JOIN_SUPER_LIST = [
    {   
        id:1, 
        borderColor:'#ff00b3',
        title:'SNS Channel', 
        link:[
            {id:1,name:'you',img:'./img/home/link1.png',link:SNS_LINK[0].link},
            {id:2,name:'tel',img:'./img/home/link2.png',link:SNS_LINK[3].link},
            {id:3,name:'insta',img:'./img/home/link3.png',link:SNS_LINK[1].link},
            {id:4,name:'twitter',img:'./img/home/link6.png',link:TWITTER_LINK},
        ],
        btnName:'Follow Now',
    },
    {   
        id:2, 
        borderColor:'#0046ff',
        title:'Playground', 
        link:[
            {id:1,name:'smile',img:'./img/home/icon1.png',link:GAME_LINK},
            {id:2,name:'good',img:'./img/home/icon2.png',link:GAME_LINK},
            {id:3,name:'heart',img:'./img/home/icon3.png',link:GAME_LINK},
            {id:4,name:'surprise',img:'./img/home/icon4.png',link:GAME_LINK},
        ],
        btnName:'Play Now',
    },
    {   
        id:3, 
        borderColor:'#d6ff00',
        title:'NFT', 
        link:[
            {id:1,name:'nftBox',img:'./img/home/asset3.png',link:'#'},
        ],
        btnName:'Coming Soon',
    },
]

export const FOOTER_LINK = [
    {id:1,img:'./img/home/link2.png', link:SNS_LINK[3].link, name:'tel'},
    {id:2,img:'./img/home/link3.png', link:SNS_LINK[1].link, name:'in'},
    {id:3,img:'./img/home/link4.png', link:NEW_MUSIC_LINK, name:'goo'},
    {id:4,img:'./img/home/link1.png', link:SNS_LINK[0].link, name:'youtube'},
];