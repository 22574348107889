import styled from '@emotion/styled'
import React from 'react'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import {AiOutlineSwapRight} from 'react-icons/ai';

interface titleType {
    type?:'center';
    title?:string;
    subTitle?:any;
    link?:string;
    padding?:string;
    colors?:Array<string>;
    colorCount?:number
}

const TitleItem = ({type,title,subTitle,link,colors,colorCount}:titleType) =>{
    return(
        <>
            <TitleWrap type={type}>
                <TitleTextItem colors={colors} colorCount={colorCount}>{title}</TitleTextItem>
                {(link !== '' && link!==undefined) &&
                    <AiOutlineSwapRight></AiOutlineSwapRight>
                }
            </TitleWrap>
            <SubTitleWrap>
                {subTitle}
            </SubTitleWrap>
        </>
    )
}

export default function TitlePart({type,title,subTitle,link,padding,colors,colorCount}:titleType) {
    return (
        <Wrap padding={padding}>
            <MaxWidthWrap>
                {type === 'center' ?
                    <CenterWrap>
                        <TitleItem type={type} title={title} subTitle={subTitle} link={link} colors={colors} colorCount={colorCount}></TitleItem>
                    </CenterWrap>
                    : <TitleItem type={type} title={title} subTitle={subTitle} link={link} colors={colors} colorCount={colorCount}></TitleItem>
                }
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{padding?:string}>`
    padding:${props => props.padding ?? '0'};
`;

const CenterWrap = styled(Center)`
    flex-direction: column;
`;

const TitleWrap = styled(Row)<{type?:string}>`
    justify-content: ${props=> props.type ?? 'space-between'};
    & svg{
        width: 80px;
        height: 80px;
    }
    @media ${({theme}:any) => theme.media.m}{
        & svg{
            width: 30px;
            height: 30px;
        }   
    }
`;
const TitleTextItem = styled.div<{colors?:Array<string>,colorCount?:number}>`
    font-size: 100px;
    line-height: 1.5;
    background: ${props => props.colors === undefined ? 
        'transparent' : 
        props.colorCount === 3 ?
        `linear-gradient(100deg, ${props.colors[0]} 0%, ${props.colors[1]} 35%, ${props.colors[2]} 100%)` :
        props.colorCount === 2 ? `linear-gradient(100deg, ${props.colors[0]} 0%, ${props.colors[1]} 100%)` : 'transparent'
    };
    color: transparent;
    -webkit-background-clip: text;
    font-weight: 900;
    @media ${({theme}:any)=>theme.media.m}{
        font-size: 36px;
    }
`;

const SubTitleWrap = styled.div`
    margin-top: 25px;
    font-size: 28px;
    line-height: 24px;
    @media ${({theme}:any)=>theme.media.m}{
        font-size: 16px;
    }
`;


