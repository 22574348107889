import styled from '@emotion/styled';
import React from 'react'
import { Row } from '../../../style/basic/commonStyle';
import PlayZoneSlideItem from '../item/PlayZoneSlideItem';
import { Fade,Zoom } from 'react-awesome-reveal';
import { useDispatch } from 'react-redux';
import { setComingSoon } from '../../../store/common/comingSoon';

export default function PlayZonePart() {
    const dispatch = useDispatch();
    const slideClick = (link:string) =>{
        if(link==='#'){
            dispatch(setComingSoon({action:true}));
            return;
        }
        window.open(link);
    }

    return (
        <Wrap>
            <ImgWrap>
                <Fade direction='left' triggerOnce delay={800}>
                    <Img>
                        <img alt='play-img' src='./img/home/playzone.png'></img>
                    </Img>
                </Fade>
            </ImgWrap>
            <SlideWrap>
                <PlayZoneSlideItem slideClick={slideClick}></PlayZoneSlideItem>
            </SlideWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    align-items: start;
    flex-wrap: wrap;
`;

const ImgWrap = styled.div`
    width: 40%;
    @media ${({theme}:any)=>theme.media.m}{
        width: 100%;
    }
`;

const Img = styled.div`
    background-color: #00c2ff;
    line-height: 0;
    width: 130%;
    border-radius: 0 350px 350px 0;
    overflow: hidden;
    text-align: center;
    & img{
        max-height: 350px;
        height: 100%;
    }
    
    @media ${({theme}:any)=>theme.media.m}{
        text-align: left;
        width: 90%;
        & img{
            max-height: 280px;
        }
    }
`;

const SlideWrap = styled.div`
    width: 60%;
    margin-top: 100px;
    transform: translateX(30px);
    z-index: 1;
    @media ${({theme}:any)=>theme.media.m}{
        transform: translateX(0px);
        margin-top: -30px;
        width: 100%;
    }
`;