import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_RECENT_GENERATE_BLOCK = 'mesh-dash/block/SET_RECENT_GENERATE_BLOCK';

export const setGenerateBlockActionCreator = createAction<any>(SET_RECENT_GENERATE_BLOCK);

export function setRecentGenerateBlock(block: any): ThunkAction<void, RootState, null, ReturnType<typeof setGenerateBlockActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setGenerateBlockActionCreator({
                block
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

const initialState: any = {
    block_number: 0,
    block_timestamp1: new Date().getTime(),
    block_timestamp4: new Date().getTime(),
    block_timestamp3: new Date().getTime(),
    loading: true
};

export const generateBlock = handleActions<any, any>({
  [SET_RECENT_GENERATE_BLOCK]: (state, action: ReturnType<typeof setGenerateBlockActionCreator>) => ({
    block_number: action.payload.block.block_number ?? state.block_number,
    block_timestamp1: action.payload.block.block_timestamp1 ?? state.block_timestamp1,
    block_timestamp4: action.payload.block.block_timestamp4 ?? state.block_timestamp4,
    block_timestamp3: action.payload.block.block_timestamp3 ?? state.block_timestamp3,
    loading: false
  }),
}, initialState);
