import styled from '@emotion/styled';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setGlobalLang } from '../../../store/common/globalLang';
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';
import { MENU_LIST } from '../../data/menu';
import {IoIosMenu, IoIosClose} from 'react-icons/io';
import MMenuPart from '../part/MMenuPart';
import { motion } from 'framer-motion';

interface headerPageType{
    menuClick:any;
}

export default function HeaderPage({menuClick}:headerPageType) {
    const {lang} = useGlobalLang();
    const [mMenu,setMMenu] = useState(false);
    const [mMenuState,setMMenuState] = useState('');
    const dispatch = useDispatch();
    
    const langChange = (la:string) =>{
        dispatch(setGlobalLang({
            data: la,
        }))
    }

    const mMenuIconClick = () =>{
        setMMenu(!mMenu);
        setMMenuState(!mMenu?'open':'close');
    }

    const logoClick = () =>{
        menuClick(6);
    }
    
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <LeftWrap onClick={logoClick}>
                        <img alt='logo' src={'./img/home/logo.png'}></img>
                    </LeftWrap>
                    <CenterWrap>
                        {MENU_LIST.map((data)=>{
                            return(
                                <MenuItem 
                                    key={`menu-${data.id}`}
                                    onClick={()=>menuClick(data.id)}
                                >{data.name}</MenuItem>
                            )
                        })}
                    </CenterWrap>
                    <RightWrap>
                        <LangSelectWrap>
                            <KoLangItem nowLang={lang} onClick={()=>langChange('ko')}>
                                KO
                            </KoLangItem>
                            <EnLangItem nowLang={lang} onClick={()=>langChange('en')}>
                                EN
                            </EnLangItem>
                            <LangBg nowLang={lang}></LangBg>
                        </LangSelectWrap>
                        <MmenuWrap>
                            <MmenuIcon onClick={mMenuIconClick}>
                                <motion.div
                                    initial={{
                                        scale: 1,
                                        rotate:360
                                    }}
                                    animate={mMenuState === 'open' ?{
                                        scale:[1,0,1],
                                        rotate:[0,180,0],
                                    }: mMenuState === 'close' ? {
                                        scale: [0,1],
                                        rotate:[360,0]
                                    }:{}}
                                    transition={{
                                        duration:0.6,
                                    }}
                                >
                                    {mMenu ? 
                                        <IoIosClose size={32} color='#fff'></IoIosClose> :
                                        <IoIosMenu size={32} color='#fff'></IoIosMenu>
                                    }
                                </motion.div>
                            </MmenuIcon>
                            <MMenuPart mMenu={mMenu} setMMenu={setMMenu} menuClick={menuClick} setMMenuState={setMMenuState}></MMenuPart>
                        </MmenuWrap>
                    </RightWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    height: 100%;
`;

const InnerWrap = styled(Row)`
    justify-content: space-between;
    height: 100%;
`;

const LeftWrap = styled(Center)`
    & img{
        max-height: 27px;
    }
    @media ${({theme}:any)=>theme.media.m}{
        width: 50%;
    }
    position: relative;
    z-index: 6;
`;
const CenterWrap = styled(Center)`
    @media ${({theme}:any)=>theme.media.m}{
        display: none;
        justify-content: start;
    }
`;
const MenuItem = styled.div`
    margin-right: 35px;
    cursor: pointer;
    &:nth-last-of-type(1){margin-right:0;}
`;
const RightWrap = styled(Center)`
    @media ${({theme}:any)=>theme.media.m}{
        width: 50%;
        justify-content: end;
    }
`;
const LangSelectWrap = styled(Row)`
    width: 100px;
    border: 1px solid #56545a;
    border-radius: 30px;
    overflow: hidden;
    background-color: #383838;
    position: relative;
    z-index: 2;
`;
const LangItem = styled(Center)`
    width: 50%;
    padding: 4px 2px;
    border-radius: 30px;
    position: relative;
    z-index: 3;
`;

const KoLangItem = styled(LangItem)<{nowLang?:string}>`
    /* background-color: ${props => props.nowLang === 'ko' ? '#fff':'#383838'}; */
    color: ${props => props.nowLang === 'ko' ? '#030303':'#fbfbfb'};
    cursor: pointer;
`;

const EnLangItem = styled(LangItem)<{nowLang?:string}>`
    /* background-color: ${props => props.nowLang === 'en' ? '#fff':'#383838'}; */
    color: ${props => props.nowLang === 'en' ? '#030303':'#fbfbfb'};
    cursor: pointer;
`;

const LangBg = styled(LangItem)<{nowLang?:string}>`
    position: absolute;
    top:0;
    left: ${props => props.nowLang === 'ko' ? '0':'50%'};
    width: 50%;
    height: 100%;
    background-color: #fff;
    z-index: 1;
    transition: 0.3s;
`;

const MmenuWrap = styled.div`
    margin-left: 15px;
    position: relative;
    display: none;
    @media ${({theme}:any)=>theme.media.m}{
        display: block;
    }
`;

const MmenuIcon = styled.div`
    cursor: pointer;
    position: relative;
    z-index: 6;
`;