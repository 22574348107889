import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_NODE_PLATFORM_MODAL_OPEN = 'mesh-dash/node/SET_NODE_PLATFORM_MODAL_OPEN';
const SET_NODE_PLATFORM_MODAL_CLOSE = 'mesh-dash/node/SET_NODE_PLATFORM_MODAL_CLOSE';

export const setNodePlatformOpenActionCreator = createAction(SET_NODE_PLATFORM_MODAL_OPEN);
export const setNodePlatformCloseActionCreator = createAction(SET_NODE_PLATFORM_MODAL_CLOSE);



export function setPlatformBlockNode(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setNodePlatformOpenActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setNodePlatformOpenActionCreator({
                data
            }));
    } catch (error) {
      console.log(error);
    }
  };
}


export interface PlatformNodeState {
    mbn_number?: any;
    icon?: any;
    name?: string;
    block?: any;
    transaction?: any;
    transaction_fee?: any;
    symbol?: string;
    link_date?: any;
    linked_wallet?: any;
    network_status?: any;
    description?: string;
    category?: string;
    visible: boolean;
}

const initialState: PlatformNodeState = {
    mbn_number: 1,
    icon: '',
    name: '',
    block: 0,
    transaction: 0,
    transaction_fee: 0,
    symbol: '',
    link_date: new Date(),
    linked_wallet: 0,
    network_status: '0',
    description: '',
    category: '',
    visible: false
};

export const nodePlatformModalState = handleActions<PlatformNodeState, any>({
    [SET_NODE_PLATFORM_MODAL_OPEN]: (state, action: ReturnType<typeof setNodePlatformOpenActionCreator>) => ({
        mbn_number: action.payload.data.mbn_number ?? 0,
        icon: action.payload.data.icon ?? '',
        name: action.payload.data.name ?? '',
        block: action.payload.data.block ?? '',
        transaction: action.payload.data.transaction ?? 0,
        transaction_fee: action.payload.data.transaction_fee ?? 0,
        symbol: action.payload.data.symbol ?? '',
        link_date: action.payload.data.link_date ?? new Date(),
        linked_wallet: action.payload.data.linked_wallet ?? 0,
        network_status: action.payload.data.network_status ?? '0',
        description: action.payload.data.description ?? '',
        category: action.payload.data.category ?? '',
        visible: true
    }),
    [SET_NODE_PLATFORM_MODAL_CLOSE]: (state, action: ReturnType<typeof setNodePlatformCloseActionCreator>) => (initialState),
}, initialState);
