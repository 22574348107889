import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_LANG = 'suworld/common/SET_LANG';

export const globalLangCreator = createAction(SET_LANG);

export function setGlobalLang(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalLangCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalLangCreator({
        data: data.data
      }));
    } catch (error) {
      
    }
  };
}

export interface ToastState {
  lang: 'ko'|'en'|string;
}

const initialState: ToastState = {
  lang:'ko',
};

export const globalLang = handleActions<ToastState, any>({
  [SET_LANG]: (state, action: ReturnType<typeof globalLangCreator>) => ({
    lang: action.payload.data ?? 'ko',
  }),
}, initialState);
