import styled from '@emotion/styled';
import React from 'react'

interface squareItemType{
    children?:any;
    height?:number;
    mHeight?: number;
    clickEvent?:any;
}

export default function SquareItem({children,height,mHeight,clickEvent}:squareItemType) {
    return (
        <Wrap height={height} mHeight={mHeight} onClick={clickEvent}>
            <InnerWrap>
                {children}
            </InnerWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{height?:number,mHeight?:number}>`
    width: 100%;
    position: relative;
    &::after{
        content:'';
        display:block ;
        padding-bottom: ${props=>props.height ?? 100}%;
    }
    @media ${({theme}:any)=>theme.media.m}{
        &::after{
            padding-bottom: ${props=>props.mHeight ?? props.height ?? 100}%;
        }
    }
`;

const InnerWrap = styled.div`
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
`;
