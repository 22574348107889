import styled from '@emotion/styled'
import React from 'react'
import { Row } from '../../../style/basic/commonStyle';
import JoinSuperWorldTitleItem from './JoinSuperWorldTitleItem';

interface joinSuperWorldContentItemType{
    borderColor?:string;
    title:string;
    color?:string;
    linkList?:any;
    clickEvent:any;
}

export default function JoinSuperWorldContentItem({borderColor,title,color,linkList,clickEvent}:joinSuperWorldContentItemType) {

    return (
        <Wrap>
            <TitleWrap>
                <JoinSuperWorldTitleItem
                    borderColor={borderColor}
                    title={title}
                    color={color}
                ></JoinSuperWorldTitleItem>
            </TitleWrap>
            <IconWrap>
                <IconSpaceWrap></IconSpaceWrap>
                <IconListWrap>
                    <IconItemWrap>
                        {linkList.map((data:any)=>{
                            return(
                                <IconItem key={`join-link-${data.id}`} linkListLength={linkList.length} onClick={()=>clickEvent(data.link)}>
                                    <img alt='icon' src={data.img}></img>
                                </IconItem>
                            )
                        })}
                    </IconItemWrap>
                </IconListWrap>
            </IconWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    height: 100%;
`;

const TitleWrap = styled.div`
    margin-top: 20px;
    margin-left: -5px;
    position: relative;
    width: 100%;
`;

const IconWrap = styled(Row)`
    width: 100%;
    height: calc(100% - 120px);
    align-items: end;
`;

const IconSpaceWrap = styled.div`
    width: 30%;
`;
const IconListWrap = styled.div`
    width: 70%;
`;

const IconItemWrap = styled(Row)`
    padding: 10px;
    flex-wrap: wrap;
`;

const IconItem = styled.div<{linkListLength:number}>`
    width: ${props=>props.linkListLength === 1 ? 100 : 50}%;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    & img{
        width: 85%;
    }
`;