import styled from '@emotion/styled'
import React from 'react'
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

interface joinSuperWorldTitleItemType{
    borderColor?:string;
    title:string;
    color?:string;
}

export default function JoinSuperWorldTitleItem({borderColor,title,color}:joinSuperWorldTitleItemType) {
    return (
        <Wrap>
            <TitleBg borderColor={borderColor}></TitleBg>
            <TitleText>
                <BasicText color={color} fontWeight={800}>
                    SUWORLD <br></br>
                    {title}
                </BasicText>
            </TitleText>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;

const TitleBg = styled.div<{borderColor?:string}>`
    border-color: ${props => props.borderColor !== undefined ? `${props.borderColor} transparent ${props.borderColor} ${props.borderColor}`: 'transparent transparent transparent transparent'};
    border-style: solid;
    border-width: 50px 50px 50px 180px;
    height: 0px;
    width: 0px;
    position: relative;
    width: 85%;
`;

const TitleText = styled(Row)`
    position: absolute;
    top: 0;
    left: 15px;
    width: 85%;
    height: 100%;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    & p{display:block; font-size:24px;}
`;