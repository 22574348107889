import styled from '@emotion/styled';
import React from 'react'
import { MaxWidthWrap } from '../../../style/basic/commonStyle';

export default function NewsPage() {
    return (
        <Wrap>
            <MaxWidthWrap>
                NewsPage
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    min-height: 800px;
`;