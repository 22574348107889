import Decimal from 'decimal.js';

export function decimalRoundOff(value: number, decimalLength: number) {
    return new Decimal(value).mul((10 ** decimalLength)).floor().div(10 ** decimalLength).toNumber();
}

export function numberWithComma(value: number | string) {
    const parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
    // return value;
}

export function valueFormat(value: number, decimalLength: number) {
    const result = decimalRoundOff(value, decimalLength);
    return numberWithComma(result);
};

export function balanceFormat(amount: any, decimalLength?: number) {
    if(typeof amount === 'string'){
        amount = parseInt(amount);
    }
    const balance = parseFloat((amount / 1000000000000000000).toFixed(4));
    return balance;
    // const result = decimalRoundOff(value, decimalLength);
    // return numberWithComma(result);
};

export function kFormatter(num: any) {
    return numberWithComma(Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000)) + 'K' : Math.sign(num)*Math.abs(num) ?? '0K')
}