import { axiosConfigInstance } from "./config";

const PATH = 'client/';

// 공지사항 아이템
export const NOTICE_ITEM = {
    "notice_id": 0,
    "notice_title": "",
    "notice_content": "",
    "notice_time": 1684473654077,
    "notice_img": []
};

// 공지사항 리스트
export const apiGetNoticeList = async(page:number,count:number) =>{
    try {
        const res = await axiosConfigInstance(`${PATH}notice`).post('',{
            method:'list',
            params:{
                page,count
            }
        });
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 공지사항 디테일
export const apiGetNoticeDetail = async(id:any) =>{
    try {
        const res = await axiosConfigInstance(`${PATH}notice`).post('',{
            method:'detail',
            params:{
                notice_id:id
            }
        });
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}