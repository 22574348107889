import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from './../store';

const SET_INIT_SECURITY_DATA = 'mesh-dash/security/SET_INIT_SECURITY_DATA';
const SET_SECURITY_DATA = 'mesh-dash/security/SET_SECURITY_DATA';

export const setInitSecurityActionCreator = createAction<any>(SET_INIT_SECURITY_DATA);
export const setSecurityActionCreator = createAction<any>(SET_SECURITY_DATA);

export function setInitSecurityCheckData(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setInitSecurityActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setInitSecurityActionCreator({
                data
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setSecurityChartData(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setSecurityActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setSecurityActionCreator({
                data: data
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

const initialState: any = {
    data: [
      {
        name: 'Defense Layer - #1',
        uv: Math.floor(Math.random() * 50),
        pv: 4567,
        fill: 'rgba(0, 223, 255, 0.54)',
      },
      {
        name: 'Defense Layer - #2',
        uv: Math.floor(Math.random() * 50),
        pv: 1398,
        fill: 'rgba(0, 200, 255, 1.0)',
      },
      {
          name: 'Defense Layer - #3',
          uv: Math.floor(Math.random() * 50),
          pv: 9800,
          fill: 'rgba(0, 150, 255, 1.0)',
      },
    ]
};

export const securityChart = handleActions<any, any>({
  [SET_INIT_SECURITY_DATA]: (state, action: ReturnType<typeof setInitSecurityActionCreator>) => ({
    data: action.payload.data
  }),
  [SET_SECURITY_DATA]: (state, action: ReturnType<typeof setSecurityActionCreator>) => {
    const prevBlockList = state.block;
    const socketData = action.payload.block;
    const result = [socketData, ...prevBlockList];
    return {
        block: result.slice(0, 13)
    }
  }
}, initialState);
