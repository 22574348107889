import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web';
import comingJson from '../../data/coming.json';
import { Center } from '../../../style/basic/commonStyle';
import { useComingSoon } from '../../../utils/custom-hooks/common/useComingSoon';
import { useDispatch } from 'react-redux';
import { setComingSoon } from '../../../store/common/comingSoon';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },ref: React.Ref<unknown>,
){ return <Slide direction="up" ref={ref} {...props} />;});

export default function ComingSoonPart() {
    const open = useComingSoon();
    const dispatch = useDispatch();
    const aniRef = useRef<HTMLDivElement>(null);

    useEffect(()=>{
        const timer = setTimeout(()=>{
            setLottie();
        },500)
        return () => clearTimeout(timer);
    },[])

    const setLottie = () =>{
        if(aniRef.current === null) return;
        lottie.loadAnimation({
            container:aniRef.current,
            renderer:'svg',
            loop:true,
            autoplay:true,
            animationData : comingJson,
        })
    }

    const close = () =>{
        dispatch(setComingSoon())
    }
    return (
        <Wrap>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={close}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <AniWrap>
                        <TextWrap>COMING <br></br>SOON</TextWrap>
                        <AniItem ref={aniRef}></AniItem>
                    </AniWrap>
                </DialogTitle>
                <DialogActions style={{justifyContent:'center'}}>
                    <Button onClick={close} variant='outlined' >OK</Button>
                </DialogActions>
            </Dialog>
        </Wrap>
    )
}

const Wrap = styled.div``;

const AniWrap = styled(Center)`
    position: relative;
    flex-direction: column;
`;
const AniItem = styled.div`
    width: 100%;
    max-width: 260px;
`;

const TextWrap = styled.div`
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: 900;
`;