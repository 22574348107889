import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_TRANSACTION_COUNT = 'mesh-dash/transaction/SET_TRANSACTION_COUNT';

export const setTransactionCountActionCreator = createAction<any>(SET_TRANSACTION_COUNT);

export function setTransactionCountThunk(transaction: any): ThunkAction<void, RootState, null, ReturnType<typeof setTransactionCountActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setTransactionCountActionCreator({
              transaction
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

const BASIC_TRANS = {count: 0, value: 0};

const initialState: any = {
  total_transaction: {
    count: 0,
    value: 0
  },
  oneDayAgo_transaction: {
    count: 0,
    value: 0
  },
  blockHeight: 0
};

export const transactionCount = handleActions<any, any>({
  [SET_TRANSACTION_COUNT]: (state, action: ReturnType<typeof setTransactionCountActionCreator>) => ({
    total_transaction: action.payload.transaction.total_transaction ?? BASIC_TRANS,
    oneDayAgo_transaction: action.payload.transaction.oneDayAgo_transaction ?? BASIC_TRANS,
    blockHeight: action.payload.transaction.blockHeight ?? 0
  }),
}, initialState);
