export const SUBOX_EMAIL = 'support@subox.co.kr';
export const GAME_LINK = 'https://play.google.com/store/apps/details?id=com.mtc.sugame';
export const MTC_LINK = 'https://testscan.meshtriple.com';
export const CNS_LINK = 'https://cemonemo.com/';
export const TWITTER_LINK = 'https://twitter.com/SUWORLD2030META';
export const ATO_LINK = 'https://atoreum.com/'
export const NEW_MUSIC_LINK = 'https://play.google.com/store/apps/details?id=com.subox_platform';

export const SNS_LINK = [
    {id:1,name:'youtube',link:'https://www.youtube.com/@suboxmusic',},
    {id:2,name:'insta',link:'https://www.instagram.com/subox.official/',},
    {id:3,name:'naver',link:'https://blog.naver.com/suboxm',},
    {id:4,name:'tel_official',link:'https://t.me/suworld_official',},
    {id:5,name:'tel_play',link:'https://t.me/SUWORLD_play',},
]

export const INFO_TEXT = [
    'Show Our Project info',
    `Welcome to SUWORLD official channel! <br><br>
    We are working on a SUWORLD 2030 META project<br>
    that everyone can enjoy together with the goal of WEB3.0!<br><br>
    #Blockchain #NFT #WEB3.0 #Metaverse #SUWORLD<br>
    #SUBOX #GAME #Playgorund #MUSIC #Mainnet #Tech`
];

export const LINK_LIST = [
    {id:1,link:SNS_LINK[0].link,name:'YOUTUBE',img:'./img/home/link1.png'},
    {id:2,link:SNS_LINK[4].link,name:'Telegram',img:'./img/home/link2.png'},
    {id:3,link:SNS_LINK[1].link,name:'Instagram',img:'./img/home/link3.png'},
    {id:4,link:TWITTER_LINK,name:'TWITTER',img:'./img/home/link6.png'},
    {id:5,link:SNS_LINK[2].link,name:'Naver Blog',img:'./img/home/link5.png'},
];

export const SU_LINK_LIST = [
    // {id:2,link:MUSIC_LINK,img:'./img/link/banner2.png'},
    {id:1,link:NEW_MUSIC_LINK,img:'./img/link/banner6.png'},
    {id:2,link:GAME_LINK,img:'./img/link/banner1.png'},
    {id:3,link:'/',img:'./img/link/banner3.png'},
    {id:4,link:MTC_LINK,img:'./img/link/banner4.png'},
    {id:5,link:ATO_LINK,img:'./img/link/banner7.png'},
    // {id:6,link:CNS_LINK,img:'./img/link/banner5.png'},
]