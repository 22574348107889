import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_RECENT_BLOCK_BAR_VALUE = 'mesh-dash/block/SET_RECENT_BLOCK_BAR_VALUE';
const SET_START_BLOCK_BAR_VALUE = 'mesh-dash/block/SET_START_BLOCK_BAR_VALUE';

export const setRecentBlockBarChartActionCreator = createAction<any>(SET_RECENT_BLOCK_BAR_VALUE);
export const setStartBlockBarChartActionCreator = createAction<any>(SET_START_BLOCK_BAR_VALUE);

export function setBlockBarChart(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setRecentBlockBarChartActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setRecentBlockBarChartActionCreator({
                data
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

const initialState: any = {
    block_bar: 0,
    block1_bar: 0,
    block4_bar: 0,
    block3_bar: 0
};

export const blockBarChar = handleActions<any, any>({
  [SET_RECENT_BLOCK_BAR_VALUE]: (state, action: ReturnType<typeof setRecentBlockBarChartActionCreator>) => ({
    block_bar: action.payload.data.block_bar ?? state.block_bar,
    block1_bar: action.payload.data.block1_bar ?? state.block1_bar,
    block4_bar: action.payload.data.block4_bar ?? state.block4_bar,
    block3_bar: action.payload.data.block3_bar ?? state.block3_bar
  }),
  
}, initialState);
