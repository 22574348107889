import styled,{css} from 'styled-components';
import { addTheme } from './addTheme';

export const MaxWidthWrap = styled.div<{maxWidth?:string,margin?:string, padding?:string}>`
    width:100%;
    height: 100%;
    max-width: ${props=>props.maxWidth ?? addTheme.maxWidth};
    margin: ${props=>props.margin ?? '0 auto'};
    padding: ${props=>props.padding ?? 0};
    @media (max-width:1200px){
        padding: ${props=>props.padding ?? addTheme.padding};
    }
`;

export const Center = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CenterWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const RowBetween = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const Left = styled.div`
    display: flex;
    align-items: left;
    justify-content: center;
`;

export const PaddingWrap = styled.div<{padding?: number}>`
    padding: ${props => props.padding ?? 0}px;
`;

export const FullWrap = styled.div<{padding?: number}>`
    width: 100%;
    height: 100%;
    padding: ${props => props.padding ?? 0}px;
`;

export const JustifyWrap = styled.div<{height?: string}>`
    justify-content: space-between;
    flex-direction: column;
    height: ${props => props.height ?? undefined};
`;

export const ItemDiv = styled.div<{width?: number, center?: boolean, height?: number}>`
    height: ${props => props.height ?? 100}%;
    max-height: ${props => props.height ?? 100}%;
    width: ${props => props.width ?? 20}%;
    display: flex;
    justify-content: ${props => props.center ? 'center' : 'flex-start'};
    align-items: center;
`;

export const ItemDivBetween = styled.div<{width?: number, center?: boolean, height?: number}>`
    height: ${props => props.height ?? 100}%;
    max-height: ${props => props.height ?? 100}%;
    width: ${props => props.width ?? 20}%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

/**
 * @param {string} flex - row/column
 * @param {string} align - 세로 정렬 기본값 center
 * @param {string} content - 가로 정렬 기본값 center
 * @param {string} wrap - 기본값 wrap
 * @param {string} margin - margin값 ex)0 0 0 4px
 * @param {string} padding - padding ex)0 0 0 4px
 */
 export const FlexBox = styled.div<{flex?:string,align?:string,content?:string,wrap?:string, margin?:string, padding?:string}>`
    display: flex;
    flex-direction: ${props => props.flex === undefined ? 'row' : 'column'};
    align-items: ${props => props.align ?? 'center'};
    justify-content: ${props => props.content ?? 'center'};
    flex-wrap: ${props => props.wrap ?? 'wrap'};
    margin: ${props=> props.margin ?? '0px'};
    padding: ${props => props.padding ?? '0px'};
`;

/**
 * @param {string} bg - 배경 컬러값
 * @param {boolean} reverse - 배경값이 border 컬러캆으로 변경
 * @param {string} padding -padding 값 ex) 0 0 0 5px
 * @param {string} margin - margin 값 ex) 0 0 0 5px
 * @param {number} size - 폰트크기
 * @param {string} color - 글자색
 * @param {string} h - 높이
 * @param {string} w - 너비
 * @param {string} border - 보더
 * @param {string} radius - 라운드
 */
 export const Button = styled.button<{reverse?:string,bg?:string,border?:string, line?:number, padding?:string, margin?:string, size?:number,color?:string, h?:string, w?:string, radius?:number}>`
 background-color: ${props => props.reverse ? 'transparent' : props.bg ?? 'transparent'};
 border: ${props => props.border !==undefined ? props.border : props.reverse ? `${props.line ?? 1}px solid ${props.bg ?? 'transparent'}` : '1px solid transparent'};
 padding : ${props => props.padding ?? '0'};
 margin : ${props => props.margin ?? '0'};
 font-size: ${props => props.size ?? 16}px;
 color : ${props => props.color ?? '#000'};
 height: ${props => props.h ?? 'auto'};
 width: ${props => props.w ?? 'auto'};
 cursor: ${props=>props.disabled ? 'default' :'pointer'};
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: ${props=>props.radius ?? 3}px;
 opacity: ${props=>props.disabled ? '0.3' :'1'};
`;

/**
 * @param {number} size - 글자 크기
 * @param {number} bold - 글자 굵기
 * @param {number} line - 글자 줄 수
 * @param {string} color - 글자색
 * @param {string} bg - 배경색
 * @param {string} margin - margin 값 ex) 0 0 0 5px
 * @param {string} padding -padding 값 ex) 0 0 0 5px
 * @param {number} lh - line-height 값
 * @param {boolean} noLimit - 글자 줄 수 제한 x
 * @param {string} text - 글자 정렬
 * @param {number} ls - 글자 간격
 * @param {number} mSize - 모바일 글자크기
 */
 export const Text = styled.p<{size?:number, bold?:number, noLimit?:boolean, bg?:string, radius?:number, margin?:string, padding?:string, lh?:number, text?:string, ls?:number, mSize?:number}>`
    font-size: ${props => props.size ?? 16}px;
    font-weight: ${props => props.bold ?? 400};
    ${props => props.noLimit ? 
        css<{line?:number,}>`display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: ${props => props.line ?? 1};
        -webkit-box-orient: vertical;`
        :
        '' 
    };
    color : ${props =>props.color ?? 'inherit'};
    background-color: ${props =>props.bg ?? 'transparent'};
    border-radius: ${props =>props.radius ?? 0}px;
    margin: ${props => props.margin ?? '0'};
    padding : ${props => props.padding ?? '0'};
    line-height: ${props => props.lh ?? (props.size ?? 16)+6}px;
    word-break: break-all;
    text-align: ${props=>props.text ?? 'left'};
    letter-spacing: ${props=>props.ls ?? 0}px;
    @media (max-width:992px){
        font-size: ${props => props.mSize ?? props.size}px;
        line-height: ${props => props.lh ?? (props.mSize ?? 16)+6}px;
    }
`;