import styled from '@emotion/styled';
import React from 'react'
import { Center } from '../../../style/basic/commonStyle';
import { MENU_LIST } from '../../data/menu';
import {motion} from 'framer-motion';

interface MMenuPartType{
    menuClick:any;
    mMenu:boolean;
    setMMenu:any;
    setMMenuState:any;
}

export default function MMenuPart({menuClick,mMenu,setMMenu,setMMenuState}:MMenuPartType) {
    const mMenuClick = (id:number) =>{
        menuClick(id);
        setMMenu(false);
        setMMenuState('close');
    }

    const close = () =>{
        setMMenu(false);
        setMMenuState('close');
    }

    return (
        // <Wrap mMenu={mMenu}>
        <motion.div
            style={{
                position: 'fixed',
                top:'-100%',
                left:0,
                width:'100%',
                height: '0',
            }}
            initial={{
                top:'-100%',
                height: '0',
                opacity: 0,
            }}
            animate={mMenu?{
                top:'0%',
                height: '100%',
                opacity: [0,1],
            }:{
                top:'-100%',
                height: '0%',
                opacity: 0,
            }}
            transition={{
                duration:0.6,
            }}
        >
            <Bg onClick={close} mMenu={mMenu}></Bg>
            <MenuWrap>
                {MENU_LIST.map((data)=>{
                    return(
                        <MenuItem 
                            key={`m-menu-${data.id}`}
                            onClick={()=>mMenuClick(data.id)}
                        >
                            {data.name}
                        </MenuItem>
                    )
                })}
            </MenuWrap>
        </motion.div>
        // </Wrap>
    )
}

const Wrap = styled.div<{mMenu:boolean}>`
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: ${props=>props.mMenu ? 'block':'none'};
`;

const Bg = styled.div<{mMenu:boolean}>`
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    display: ${props=>props.mMenu ? 'block':'none'};
`;

const MenuWrap = styled(Center)`
    position: absolute;
    top:${({theme}:any)=>theme.headerHeight};
    right:0;
    width: 100%;
    height: 40%;
    background-color: #333;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
`;

const MenuItem = styled.div`
    /* margin-top: 20%; */
`;