import { Typography } from '@mui/material';
import styled from 'styled-components';
import { useTheme } from '../../utils/custom-hooks/common/useTheme';
import { numberWithComma } from '../../utils/format/number';

interface BasicTextProps {
    noWrap?: boolean;
    fontFamily?: string;
    fontSize?: number;
    children?: any;
    textAlign?: any;
    fontWeight?: string | number;
    theme?: any;
    color?: any;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    xs?: boolean;
    md?: boolean;
    comma?: boolean;
    hover?: boolean;
    tooltip?: boolean;
    tooltipText?: string;
    dangerouslySetInnerHTML?:any;
}

export default function BasicText({noWrap, fontFamily, fontSize, theme, color, children, textAlign, fontWeight, marginTop, marginBottom, marginLeft, marginRight, xs, md, comma, hover, tooltip, tooltipText, ...rest}: BasicTextProps){

    const themeColor = useTheme();

    return (
        <>
        <Text
            noWrap={noWrap ?? false}
            fontSize={fontSize}
            fontFamily={fontFamily ?? 'unset'}
            color={color ?? themeColor[theme]}
            textAlign={textAlign}
            fontWeight={fontWeight}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            hover={hover}
            // variant="button"
            sx={
                {
                    display: {
                        xs: xs ? 'none' : 'flex',
                        md: md ? 'none' : 'flex'
                    }
                }
            }
            {...rest}
            >
            {comma ? numberWithComma(children) : children}
        </Text>
        </>
    )
}

const Text = styled(Typography)<{
    marginTop?: number,
    marginBottom?: number,
    marginLeft?: number,
    marginRight?: number,
    hover?: boolean
}>`
    margin-top: ${props => props.marginTop ?? 0}px;
    margin-bottom: ${props => props.marginBottom ?? 0}px;
    margin-left: ${props => props.marginLeft ?? 0}px;
    margin-right: ${props => props.marginRight ?? 0}px;

    &:hover {
        ${props => props.hover ? 
            `
            color: #00b3ff;
            text-shadow: 
                0 0 10px #00b3ff,
                0 0 20px #00b3ff,
                0 0 40px #00b3ff,
                0 0 80px #00b3ff,
                0 0 120px #00b3ff;
            ` : ``
        }
    }
`;
