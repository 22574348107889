import styled from '@emotion/styled';
import { log } from 'console';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { Center, MaxWidthWrap, RowBetween } from '../../../style/basic/commonStyle';
import { apiGetNoticeDetail } from '../../../api/api';
import ToastViewerItem from '../item/ToastViewerItem';
import BasicText from '../../basic/BasicText';
import { momentFormat } from '../../../utils/format/time';
import { IMG_URL } from '../../../api/config';


export default function NewsDetailPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [title,setTitle] = useState('');
    const [time,setTime] = useState('0');
    const [img,setImg] = useState([]);
    const [content,setContent] = useState('');

    useEffect(()=>{
        if(id === undefined || id === null){
            navigate('/');
        }else{
            // getDetail();
        }
    },[id])

    const getDetail = async() =>{
        const res = await apiGetNoticeDetail(id);
        
        if(res.result){
            setTitle(res.data.notice_title);
            setContent(res.data.notice_content);
            setImg(res.data.notice_img);
            setTime(momentFormat('yyyy-MM-DD HH:mm',res.data.notice_time));
        }else{
            setTitle('');
            setContent('');
            setImg([]);
            setTime('0');
        }
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <TitleWrap>
                        <BasicText fontSize={22}>{title}</BasicText>
                        <BasicText marginTop={0.5} fontSize={16}>{time}</BasicText>
                    </TitleWrap>
                    <ImgWrap>
                        {img.length !== 0 &&
                            img.map((data,index)=>{
                                return(
                                    <img key={`img-${index}`} src={`${IMG_URL}${data}`} alt='notice-img'></img>
                                )
                            })
                        }
                    </ImgWrap>
                    <ContentWrap>
                        <ToastViewerItem content={content}></ToastViewerItem>
                    </ContentWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    min-height: 800px;
`;

const InnerWrap = styled.div`
    padding-top: 100px;
`;

const TitleWrap = styled.div`
    border-bottom: 1px solid #eee;
    padding-bottom: 14px;
    margin-bottom: 14px;
`;

const ImgWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    & img{
        margin: 10px 0;
        max-width: 100%;
    }
`;

const ContentWrap = styled.div`
    margin-top: 15px;
`;