import styled from '@emotion/styled';
import React from 'react';
import {AiOutlineSwapRight} from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { setComingSoon } from '../../../store/common/comingSoon';
import { Center, Row } from '../../../style/basic/commonStyle';
import SquareItem from '../../common/item/SquareItem';

interface blockListItemType{
    title:string;
    desc:string;
    img:string;
    link:string;
    activeState:boolean;
    id:number;
    clickEvent?:any;
}

export default function BlockListItem({title,desc,img,link,activeState,id,clickEvent}:blockListItemType) {
    const dispatch = useDispatch();
    const goScanClick = () =>{
        if(link==='#'){
            dispatch(setComingSoon({
                action:true,
            }))
            return;
        }
        window.open(link);
    }

    return (
        <Wrap activeState={activeState} onClick={clickEvent}>
            <ImgWrap>
                <SquareItem>
                    <ImgItem>
                        <img alt='blockchain-img' src={img}></img>
                    </ImgItem>
                </SquareItem>
            </ImgWrap>
            <ContentWrap>
                <TitleWrap>{title}</TitleWrap>
                <DescWrap dangerouslySetInnerHTML={{__html: desc.replaceAll('\n','<br>') }}></DescWrap>
            </ContentWrap>
            <BtnWrap>
                <BtnItem onClick={goScanClick}>
                    {id === 1 ?
                        'GO Scan' :
                        'Try now'
                    } <AiOutlineSwapRight></AiOutlineSwapRight>
                </BtnItem>
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)<{activeState:boolean}>`
    width: 100%;
    height: 100%;
    padding: 20px 15px;
    border-radius: 10px;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    background-color: #362f3c;
    background: linear-gradient(135deg, rgba(37,30,43,1) 0%, rgba(66,60,71,1) 50%, rgba(46,39,51,1) 100%);
    position: relative;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        border: 1px solid ${props => props.activeState ? ' #ddd' : 'transparent'};
        border-radius: 10px;
        z-index: 1;
    }
`;

const ImgWrap = styled.div`
    width: 46px;
`;

const ImgItem = styled(Center)`
    width: 100%;
    height: 100%;
    background-color: #0060ff;
    border-radius: 4px;
    overflow: hidden;
    & img{
        width:80%;
        max-height: 100%;
    }
`;

const ContentWrap = styled.div``;
const TitleWrap = styled.div`
    font-weight: 700;
    font-size: 19px;
    min-height: 40px;
    margin-top: 10px;
`;
const DescWrap = styled.div`
    margin-top: 10px;
    font-size: 15px;
    min-height: 62px;
`;


const BtnWrap = styled(Row)`
    justify-content: end;
    position: relative;
    z-index: 2;
    margin-top: 16px;
`;

const BtnItem = styled(Center)`
    background-color: #fff;
    border: #b9b7bb;
    color: #4d4d4d;
    padding: 8px 10px;
    border-radius: 30px;
    & svg{margin-left:5px;}
    cursor: pointer;
`;