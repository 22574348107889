import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import { RGBA_ASTC_10x10_Format } from 'three';
import {RootState} from './../store';

const SET_RECENT_BLOCK_LIST = 'mesh-dash/block/SET_RECENT_BLOCK_LIST';
const SET_START_BLOCK_LIST = 'mesh-dash/block/SET_START_BLOCK_LIST';

export const setRecentBlockActionCreator = createAction<any>(SET_RECENT_BLOCK_LIST);
export const setStartBlockActionCreator = createAction<any>(SET_START_BLOCK_LIST);

export function setRecentBlock(block: any): ThunkAction<void, RootState, null, ReturnType<typeof setRecentBlockActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setRecentBlockActionCreator({
                block
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setStartBlock(block: any): ThunkAction<void, RootState, null, ReturnType<typeof setStartBlockActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setStartBlockActionCreator({
                block: block
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

const initialState: any = {
    block: []
};

export const recentBlockList = handleActions<any, any>({
  [SET_START_BLOCK_LIST]: (state, action: ReturnType<typeof setStartBlockActionCreator>) => ({
    block: action.payload.block ?? []
  }),
  [SET_RECENT_BLOCK_LIST]: (state, action: ReturnType<typeof setRecentBlockActionCreator>) => {
    const prevBlockList = state.block;
    const socketData = action.payload.block;
    // prevBlockList.unshift(socketData);
    const result = [socketData, ...prevBlockList];
    return {
        block: result.slice(0, 10)
    }
  }
}, initialState);
