import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setComingSoon } from '../../../store/common/comingSoon';
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import useLang from '../../../utils/custom-hooks/common/useLang';
import SquareItem from '../../common/item/SquareItem';
import { BLOCK_CHAIN_CONTENT, BLOCK_CHAIN_IMG, BLOCK_CHAIN_IMG_COLOR } from '../../data/content';
import BlockListItem from '../item/BlockListItem';

export default function BlockChainPart() {
    const [activeNum,setActiveNum] = useState(0);
    const dispatch = useDispatch();

    const desc = useLang(1);

    const itemClick = (num:number) =>{
        setActiveNum(num);
    }

    useEffect(()=>{
        const timer = setInterval(()=>{
            if((BLOCK_CHAIN_CONTENT.length-1) === activeNum){
                setActiveNum(0);
                return;
            }
            setActiveNum(num=>num+1);
        },3000);
        return () => clearInterval(timer);
    },[setActiveNum,activeNum])

    const btnClick = () =>{
        const link = BLOCK_CHAIN_CONTENT[activeNum].more;
        if(link === '#'){
            dispatch(setComingSoon({action:true}));
            return;
        }
        window.open(link);
    }
    
    return (
        <Wrap>
            <MaxWidthWrap>
                <ContentWrap>
                    <ListWrap>
                        {BLOCK_CHAIN_CONTENT[0].id !== 0 &&
                            BLOCK_CHAIN_CONTENT.map((data,index)=>{
                                return(
                                    // <SquareItem key={`blockchain-item${data.id}`} height={80} mHeight={'auto'} clickEvent={()=>itemClick(index)}>
                                        <BlockListItem
                                            key={`blockchain-item${data.id}`}
                                            activeState={activeNum===index}
                                            img={data.img} 
                                            title={data.title}
                                            desc={desc[index]}
                                            link={data.link}
                                            id={data.id}
                                            clickEvent={()=>itemClick(index)}
                                        ></BlockListItem>
                                    // </SquareItem>
                                )
                            })
                        }
                    </ListWrap>
                    <SpaceWrap></SpaceWrap>
                    <ViewWrap>
                        <SquareItem>
                            <ImgWrap>
                                {BLOCK_CHAIN_IMG.map((data,index)=>{
                                    return(
                                        <Img activeState={index===activeNum} key={`block-img-${data.id}`} alt='block-img' src={data.img}></Img>
                                    )
                                })}
                                {BLOCK_CHAIN_IMG_COLOR.map((data,index)=>{
                                    return(
                                        <ImgBgWrap key={`bg-color-${data.id}`} activeState={index===activeNum}>
                                            <ImgBg1 color={data.color1} activeState={index===activeNum}></ImgBg1>
                                            <ImgBg2 color={data.color2} activeState={index===activeNum}></ImgBg2>
                                        </ImgBgWrap>
                                    )
                                })}
                            </ImgWrap>
                        </SquareItem>
                        <BtnWrap onClick={btnClick}>MORE</BtnWrap>
                    </ViewWrap>
                </ContentWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    position: relative;
`;

const ContentWrap = styled(Row)`
    flex-wrap: wrap;
`;

const ContentItemWrap = styled(Center)`
    @media ${({theme}:any)=>theme.media.m}{
        width:100%;
    }
`;

const ListWrap = styled(ContentItemWrap)`
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media ${({theme}:any)=>theme.media.s}{
        grid-template-columns: 1fr 1fr;
    }
`;

const SpaceWrap = styled.div`
    width: 10%;
`;

const ViewWrap = styled(ContentItemWrap)`
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ImgWrap = styled.div`
    height: 100%;
    position: relative;
    overflow: hidden;
`;

const ImgBgWrap = styled(Center)<{activeState:boolean}>`
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    right:${props=>props.activeState ? '0':'-100%'};
    flex-direction: column;
    align-items: end;
    z-index: 2;
    transition: 0.4s ease;
`;

const ImgBg1 = styled.div<{color:string,activeState:boolean}>`
    width: ${props=>props.activeState ? '60%':'0'};
    height: 20%;
    background-color: ${props=>props.color ?? 'transparent' };
    border-radius: 100px 0 0 100px;
    transition: 0.4s ease;
    transition-delay: 0.4s;
`;
const ImgBg2 = styled.div<{color:string,activeState:boolean}>`
    width: ${props=>props.activeState ? '100%':'0'};
    height: 20%;
    background-color:${props=>props.color ?? 'transparent' };
    border-radius: 100px 0 0 100px;
    transition: 0.4s ease;
    transition-delay: 0.6s;
`;

const Img = styled.img<{activeState:boolean}>`
    max-width: 100%;
    position: absolute;
    top:0;
    right:${props=>props.activeState ? '0':'-100%'};
    transform: ${props=>props.activeState ? 'scale(1)':'scale(0.6)'};
    opacity: ${props=>props.activeState ? '1':'0.8'};
    transition: 0.4s ease-in-out;
    z-index: 5;
`;

const BtnWrap = styled.button`
    padding: 10px 27px;
    background-color: #5000ff;
    font-size: 18px;
    border-radius: 25px;
    color: #fff;
`;