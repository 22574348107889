import styled from '@emotion/styled';
import React, { useState } from 'react'
import { Center, MaxWidthWrap, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import TotalTitlePart from '../part/TotalTitlePart';
import {BiChevronDown} from 'react-icons/bi';
import { LINK_LIST, INFO_TEXT, SU_LINK_LIST } from '../../data/linkData';


export default function TotalPage() {
    const [infoOpen,setInfoOpen] = useState(false);
    const [infoText,setInfoText] = useState(INFO_TEXT[0]);

    const infoClick = () => {
        setInfoOpen(!infoOpen);
    }

    const snsClick = (link:string) =>{
        if(link === '#') return;
        window.open(link,'_blank');
    }

    return (
        <Wrap>
            <MaxWidthWrap maxWidth='500px'>
                <TitleWrap>
                    <TotalTitlePart></TotalTitlePart>
                </TitleWrap>
                <SnsLinkWrap>
                    {LINK_LIST.map((data)=>{
                        return(
                            <SnsItemWrap key={`sns-link-${data.id}`} href={data.link} target='_blank' rel='noopener noreferrer'>
                                <img alt='sns-icon' src={data.img}></img>
                                {/* <SnsItem>
                                    <BasicText fontSize={14}>{data.name}</BasicText>
                                </SnsItem> */}
                            </SnsItemWrap>
                        )
                    })}
                </SnsLinkWrap>
                <InfoWrap onClick={infoClick}>
                    <InfoCloseWrap infoOpen={infoOpen}>
                        <BasicText dangerouslySetInnerHTML={{__html: infoOpen ? INFO_TEXT[1] : INFO_TEXT[0].replaceAll('\n','<br>') }}></BasicText>
                        {!infoOpen &&
                            <BiChevronDown size={20}></BiChevronDown>
                        }
                    </InfoCloseWrap>
                </InfoWrap>
                <SuLinkWrap>
                    {SU_LINK_LIST.map((data)=>{
                        return(
                            <SuLinkItem key={`su-link-${data.id}`} href={data.link} target='_blank' rel='noopener noreferrer'>
                                <img alt='link-img' src={data.img}></img>
                            </SuLinkItem>
                        )
                    })}
                </SuLinkWrap>
                <Center>
                    <BasicText marginTop={5} marginBottom={1.5} fontSize={13} color='#949494'>SUBOX.corp</BasicText>
                </Center>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;

const TitleWrap = styled.div`
    margin-top: 50px;
`;

const InfoWrap = styled.div`
    background-color: #ebebeb;
    border-radius: 4px;
    padding: 10px 12px;
    margin-top: 20px;
`;
const InfoCloseWrap = styled(RowBetween)<{infoOpen:boolean}>`
    align-items: ${props => props.infoOpen ? 'start' : 'center'};
    & p{
        font-size: ${props=>props.infoOpen ? '13px' : '15px'};
    }
`;

const SnsLinkWrap = styled.div`
    margin-top: 25px;
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px; */
    /* @media ${({theme}:any)=>theme.media.s}{
        grid-template-columns: 1fr;
    } */
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SnsItemWrap = styled.a`
    width: 40px;
    margin: 2% 10px;
    & img{
        width: 100%;
    }
`;

// const SnsItem = styled(RowBetween)`
//     border: 1px solid #ddd;
//     border-radius: 6px;
//     padding: 10px 12px;
    
// `;

const SuLinkWrap = styled.div`
    margin-top: 15px;
`;
const SuLinkItem = styled.a`
    border-radius:6px;
    overflow: hidden;
    display: block;
    margin-top: 10px;
    & img{
        width: 100%;
        transform: scale(1.02);
    }
`;