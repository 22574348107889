import styled from '@emotion/styled';
import React from 'react'
import { Center } from '../../../style/basic/commonStyle';
import {COLORS} from '../page/HomePage_';

export default function BgMoreBtnItem({clickEvent,title}:any) {
    const btnName = title === undefined ? 'MORE' : title;
    const btnClick = () =>{
        clickEvent();
    }

    return (
        <BtnBg onClick={btnClick}>
            <Btn colors={COLORS}>{btnName}</Btn>
        </BtnBg>
    )
}

const BtnBg = styled(Center)`
    margin-top: 60px;
    padding: 12px;
    width: 180px;
    background-color: #362f3c;
    background: linear-gradient(135deg, rgba(37,30,43,1) 0%, rgba(66,60,71,1) 50%, rgba(46,39,51,1) 100%);
    border-radius: 30px;
    cursor: pointer;
`;

const Btn = styled(Center)`
    font-size: 32px;
    background: ${props => `linear-gradient(100deg, ${props.colors[2][0]} 0%, ${props.colors[2][1]} 100%)`};
    color: transparent;
    -webkit-background-clip: text;
    font-weight: 900;
`;