import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react'
import { Center } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import {motion} from 'framer-motion';
import lottie from 'lottie-web';
import ufoJson from '../../data/ufo.json';
import earthJson from '../../data/earth.json';
import { GAME_LINK } from '../../data/linkData';

export default function BannerPart() {
    const ufoWrap = useRef<HTMLDivElement>(null);
    const ufo = useRef<HTMLDivElement>(null);
    const personWrap = useRef<HTMLDivElement>(null);
    const earth = useRef<HTMLDivElement>(null);

    useEffect(()=>{
        // ufo 로티
        if(ufo.current === null) return;
        lottie.loadAnimation({
            container:ufo.current,
            renderer:'svg',
            loop:true,
            autoplay:true,
            animationData : ufoJson,
        })
    },[]);

    useEffect(()=>{
        // 지구 로티
        if(earth.current === null) return;
        lottie.loadAnimation({
            container:earth.current,
            renderer:'svg',
            loop:true,
            autoplay:true,
            animationData : earthJson,
            name:'earth'
        })
        lottie.setSpeed(0.9);
    },[])

    // 마우스 움직일때 ani
    const mouseAni = (e:any) =>{
        if(ufoWrap.current === null) return;
        if(personWrap.current === null) return;
        ufoWrap.current.style.transform = `translate3d(${e.pageX * 0.015}px, ${e.pageY *0.015}px, 0)`
        personWrap.current.style.transform = `translate3d(${e.pageX * -0.01}px, ${e.pageY * -0.01}px, 0)`
    }

    const btnClick = () =>{
        window.open(GAME_LINK);
    }

    return (
        <Wrap onMouseMove={mouseAni}>
            <EarthWrap ref={earth}></EarthWrap>
            <ImgWrap ref={ufoWrap}>
                <UfoImgWrap ref={ufo}></UfoImgWrap>
            </ImgWrap>
            <ImgWrap ref={personWrap}>
                <ManImgWrap>
                    <motion.img
                        alt='man-img'
                        src='./img/home/asset2.png'
                        initial={{
                            rotate:0,
                        }}
                        animate={{
                            rotate: [-1,2],
                            translateX:[-15,0],
                            translateY:[0,15],
                        }}
                        transition={{
                            repeat:Infinity,
                            repeatType:'mirror',
                            duration:1.5,
                        }}
                    ></motion.img>
                </ManImgWrap>
            </ImgWrap>
            <TextWrap>
                <BasicText fontSize={32}>LET'S PLAY TOGETHER</BasicText>
                <Text>SUWORLD</Text>
                <BasicText marginTop={5} fontSize={32}>Create and Share</BasicText>
                <BasicText fontSize={32}>your super world</BasicText>
                <BtnWrap>
                    <Btn onClick={btnClick}>
                        <BasicText fontWeight={900} fontSize={22}>JOIN NOW</BasicText>
                    </Btn>
                </BtnWrap>
            </TextWrap>
        </Wrap>
    )
}

const Wrap =styled.div`
    position: relative;
    height: ${({theme}:any)=>`calc(100vh - ${theme.headerHeight})`};
    background: url('./img/home/bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
`;

const TextWrap = styled(Center)`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    @media ${({theme})=>theme.media.m}{
        & p{
            font-size: 24px;
        }
    }
`;

const ImgWrap = styled.div`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
`;

const EarthWrap = styled(ImgWrap)``;

const UfoImgWrap = styled.div`
    position: absolute;
    left:15%;
    top:5%;
    transform: rotate(-30deg);
    max-width: 300px;
    width: 100%;
    @media ${({theme}:any)=>theme.media.m}{
        display: none;
    }
`;
const ManImgWrap = styled.div`
    position: absolute;
    right:10%;
    top:50%;
    transform: rotate(25deg) translateY(-50%);
    & img{
        max-width: 250px;
        width: 100%;
    }
    @media ${({theme}:any)=>theme.media.m}{
        display: none;
    }
`;

const Text = styled.div`
    -webkit-text-stroke: 2px #fff; 
    font-size: 150px; 
    font-weight: 500; 
    letter-spacing: 4px;
    color: transparent;
    margin-top: 20px;
    @media (max-width:992px){
        font-size: 55px; 
    }
`;

const BtnWrap = styled.div`
    margin-top: 30px;
`;

const Btn = styled.div`
    background-color: rgba(255,255,255,0.3);
    border-radius: 25px;
    padding: 8px 28px;
    border: 1px solid #423c47;
    cursor: pointer;
`;