import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react'
import { MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import lottie from 'lottie-web';
import rocketJson from '../../data/rocket.json';
import { SUBOX_EMAIL } from '../../data/linkData';

export default function ContactUsPart() {
    const rocket = useRef<HTMLDivElement>(null);
    useEffect(()=>{
        if(rocket.current === null) return;
        lottie.loadAnimation({
            container:rocket.current,
            renderer:'svg',
            loop:true,
            autoplay:true,
            animationData : rocketJson,
        })
    },[])
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <TextWrap>
                        <BasicText fontWeight={900}>Contact us</BasicText>
                        <BtnWrap>
                            <Btn href={`mailto:${SUBOX_EMAIL}`}>for your Develop</Btn>
                            <Btn href={`mailto:${SUBOX_EMAIL}`}>for your Business</Btn>
                        </BtnWrap>
                    </TextWrap>
                    <ImgWrap ref={rocket}>
                        {/* <img alt='contact-img' src='./img/home/asset4.png'></img> */}
                    </ImgWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    background-color: #00125d;
`;

const InnerWrap = styled(RowBetween)`
    flex-wrap: wrap;
    /* padding: 30px 0; */
    position: relative;
    @media ${({theme}:any)=>theme.media.m}{
        padding: 65px 0 15px;
    }
`;

const TextWrap = styled.div`
    width: 30%;
    text-align: center;
    &>p{display:block; font-size:42px; letter-spacing:2px;}
    /* padding: 55px 0; */
    @media ${({theme}:any)=>theme.media.m}{
        width: 100%;
    }
`;

const ImgWrap = styled.div`
    width: 100%;
    max-width: 500px;
    /* text-align: right;
    & img{
        max-width: 300px;
        width: 100%;
        margin-right: 40px;
    } */
    @media ${({theme}:any)=>theme.media.m}{
        width: 100%;
        text-align: center;
        & img{
            margin-right: 0px;
        }
    }
`;

const BtnWrap = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Btn = styled.a`
    color: #0448ff;
    background-color: #fff;
    border-radius: 30px;
    width: 100%;
    max-width: 195px;
    min-height: 50px;
    margin-top: 15px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

