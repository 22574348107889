import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_RECENT_BLOCK_NODE_BAR_VALUE = 'mesh-dash/block/SET_RECENT_BLOCK_NODE_BAR_VALUE';

export const setRecentBlockNodeBarChartActionCreator = createAction<any>(SET_RECENT_BLOCK_NODE_BAR_VALUE);

export function setBlockNodeBarChart(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setRecentBlockNodeBarChartActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setRecentBlockNodeBarChartActionCreator({
                data
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

const initialState: any = {
    block_bar_value: [0, 0, 0, 0, 0, 0, 0, 0]
};

export const blockNodeBarChart = handleActions<any, any>({
  [SET_RECENT_BLOCK_NODE_BAR_VALUE]: (state, action: ReturnType<typeof setRecentBlockNodeBarChartActionCreator>) => ({
    block_bar_value: action.payload.data ?? state.block_bar_value
  }),
  
}, initialState);
