import styled from '@emotion/styled';
import React from 'react'
import BannerPart from '../part/BannerPart';
import BlockChainPart from '../part/BlockChainPart';
import ContactUsPart from '../part/ContactUsPart';
import JoinSuperWorldPart from '../part/JoinSuperWorldPart';
import NoticePart from '../part/NoticePart';
import PlayTogetherPart from '../part/PlayTogetherPart';
import PlayZonePart from '../part/PlayZonePart';
import TitlePart from '../part/TitlePart';
import { Fade } from 'react-awesome-reveal';

export const COLORS = [
    ['rgba(1,251,229,1)','rgba(34,165,255,1)','rgba(172,0,255,1)'],
    ['rgba(255,240,2,1)','rgba(255,29,72,1)','rgba(255,1,221,1)'],
    ['rgba(194,247,255,1)','rgba(8,224,255,1)'],
]

export default function HomePage() {
    return (
        <Wrap>
            <BannerPart></BannerPart>
            <BlockChainWrap id='blockchain'>
                <TitlePart 
                    colors={COLORS[0]} 
                    colorCount={3}
                    title='BlockChain' subTitle='지금 슈월드는 WEB 3.0 기반의 최신 기술을 이끌어가고 있습니다.' 
                    link='#' 
                    padding='50px 0 60px'
                ></TitlePart>
                <BlockChainPart></BlockChainPart>
            </BlockChainWrap>
            <PlayZoneWrap>
                <TitlePart 
                    colors={COLORS[1]} 
                    colorCount={3}
                    title='Play Zone'
                    subTitle='Create & Play with your super world' 
                    link='#'
                    padding='200px 0 60px'
                ></TitlePart>
                <PlayZonePart></PlayZonePart>
            </PlayZoneWrap>
            <PlayTogether>
                <TitlePart 
                    colors={COLORS[0]} 
                    colorCount={3}
                    type='center' 
                    title='Play Together'
                    subTitle="LET'S PLAY TOGETHER"
                    padding='200px 0 60px'
                ></TitlePart>
                <PlayTogetherPart></PlayTogetherPart>
            </PlayTogether>
            <JoinSuperWorldWrap>
                <TitlePart
                    colors={COLORS[1]}
                    colorCount={3}
                    type='center'
                    title='Join Super World'
                    subTitle="LET'S PLAY TOGETHER"
                    padding='200px 0 60px'
                ></TitlePart>
                <JoinSuperWorldPart></JoinSuperWorldPart>
            </JoinSuperWorldWrap>
            <NoticeWrap>
                <TitlePart
                    colors={COLORS[2]}
                    colorCount={2}
                    type='center'
                    title='NOTICE'
                    padding='200px 0 60px'
                ></TitlePart>
                <NoticePart></NoticePart>
            </NoticeWrap>
            <ContactUsWrap>
                <ContactUsPart></ContactUsPart>    
            </ContactUsWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    height: 100%;
    overflow: hidden;
`;

const PartWrap = styled.div`
    position: relative;
`;

const BlockChainWrap = styled.div``;
const PlayZoneWrap = styled.div``;
const PlayTogether = styled.div``;
const JoinSuperWorldWrap = styled.div``;
const NoticeWrap = styled.div``;
const ContactUsWrap = styled.div`
    padding-top: 200px;
`;