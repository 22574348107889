import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_COMING_SOON = 'suworld/common/SET_coming_SOON';

export const comingSoonCreator = createAction(SET_COMING_SOON);

export function setComingSoon(active?: any): ThunkAction<void, RootState, null, ReturnType<typeof comingSoonCreator>> {
    return async function (dispatch) {
    try {
            dispatch(comingSoonCreator({
              active: active
            }));
    } catch (error) {
      
    }
  };
}

export interface comingSoonState {
    active: boolean;
}

const initialState: comingSoonState = {
    active: false
};

export const comingSoon = handleActions<comingSoonState, any>({
  [SET_COMING_SOON]: (state, action: ReturnType<typeof comingSoonCreator>) => ({
    active: action.payload.active ?? false
  }),
}, initialState);
