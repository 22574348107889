import {createGlobalStyle, css} from 'styled-components';
import StyledReset from 'styled-reset';
import {MyReset} from './my-reset';

const font = css`
  body {
    font-family: "Malgun Gothic", sans-serif;
    font-size: 15px;
    
    &.en, .en {
      font-family: EnglishFont, sans-serif;
      line-height: 1.2;
    }
    
    &.cn, .cn {
      font-family: ChineseFont, sans-serif;
    }
  }
`;

const layout = css`
  
  html, body, #root {
    /* height: 100%; */
  }
  
  #root {
    display: flex;
    flex-direction: column;
  }
`;

const reset = css`
  ${StyledReset};
  ${MyReset};
`;

const container = css`
  @media(min-width:576px){
    .container{max-width: 540px;}
  }

  @media(min-width:768px){
    .container{max-width: 720px;}
  }
  @media(min-width:992px){
    .container{max-width: 960px;}
  }
  @media(min-width:1200px){
    .container{max-width: 1450px;}
  }
  .container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
  }
`;

export const notSelect = css`
  -webkit-user-select: none;
  -moz-user-select: none;
`;

export const GlobalStyle = createGlobalStyle`

  ${reset};
  ${layout};

  a {
    color: inherit;
  }
  
  body {
    // background: ${(props: any) => '#000'};
    /* background: #000; */
    // padding: 10px;
    ${notSelect}
    /* font-family: 'roboto', 'nanum', sans-serif; */
    font-family: 'noto', sans-serif;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  ${container};
`;

