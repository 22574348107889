import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_FULL_SCREEN = 'mesh-dash/common/SET_FULL_SCREEN';

export const fullScreenActionCreator = createAction(SET_FULL_SCREEN);

export function setFullScreen(active: any): ThunkAction<void, RootState, null, ReturnType<typeof fullScreenActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(fullScreenActionCreator({
              active: active
            }));
    } catch (error) {
      
    }
  };
}

export interface fullScreenState {
    active?: boolean;
}

const initialState: fullScreenState = {
    active: false
};

export const fullScreen = handleActions<fullScreenState, any>({
  [SET_FULL_SCREEN]: (state, action: ReturnType<typeof fullScreenActionCreator>) => ({
    active: action.payload.active ?? false
  }),
}, initialState);
