import styled from '@emotion/styled';
import React from 'react';
import { Center } from '../../../style/basic/commonStyle';
import BgMoreBtnItem from '../item/BgMoreBtnItem';
import {COLORS} from '../page/HomePage_';
import { PLAY_TOGETHER_CONTENT } from '../../data/content';
import { SUBOX_EMAIL } from '../../data/linkData';

export default function PlayTogetherPart() {
    const btnClick = () =>{
        window.location.href=(`mailto:${SUBOX_EMAIL}`);
    }

    return (
        <Wrap>
            <ContentWrap>
                {PLAY_TOGETHER_CONTENT.map((data)=>{
                    return(
                        <ContentItemWrap key={`together-${data.id}`}>
                            {data.text}
                        </ContentItemWrap>
                    )
                })}
            </ContentWrap>
            <BtnWrap>
                <BgMoreBtnItem clickEvent={btnClick} title='Join us'></BgMoreBtnItem>
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const ContentWrap = styled.div`
    max-width: 950px;
    width: 100%;
    margin: 25px auto 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media ${({theme}:any)=>theme.media.s}{
        gap: 0px;
        padding: 0 5px;
        /* display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 0;
        padding: 0 10px; */
    }
`;

const ContentItemWrap = styled(Center)`
    font-size: 52px;
    font-weight: 900;
    padding: 20px;
    word-break: break-all;
    text-align: center;
    @media ${({theme}:any)=>theme.media.s}{
        font-size: 24px;
        padding: 40px 0;
        /* width: 50%; */
    }
`; 

const BtnWrap = styled(Center)``;