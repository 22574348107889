import { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from '../components/main/page/MainPage';
import { useDispatch } from 'react-redux';
import TotalPage from '../components/total/page/TotalPage';

export default function Router(){

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/*' element={<MainPage/>} />
                <Route path='/link' element={<TotalPage/>}></Route>
            </Routes>
        </BrowserRouter>
    )
}