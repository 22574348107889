import styled from '@emotion/styled';
import React from 'react';
import { Center, Row } from '../../../style/basic/commonStyle';
import SquareItem from '../../common/item/SquareItem';
import { JOIN_SUPER_LIST } from '../../data/content';
import JoinSuperWorldContentItem from '../item/JoinSuperWorldContentItem';
import { Fade } from 'react-awesome-reveal';
import { GAME_LINK } from '../../data/linkData';
import { useNavigate } from 'react-router';

export default function JoinSuperWorldPart() {
    const navigate = useNavigate();

    const iconClick = (link:string) =>{
        
        if(link==='#'){
            return;
        }
        window.open(link);
    }

    const BtnClick = (id:number)=>{
        if(id === 1){
            navigate('/link');
        }
        if(id === 2){
            window.open(GAME_LINK);
        }
    }

    return (
        <Wrap>
            <ContentWrap>
                {JOIN_SUPER_LIST.map((data,index)=>{
                    return(
                        <InnerWrap key={`join-${data.id}`}>
                            <Fade direction='bottom-right' triggerOnce delay={500}>
                                <SquareItem height={120} mHeight={95}>
                                    <ContentItemWrap>
                                        <ContentItem borderColor={data.borderColor}>
                                            <Content>
                                                <JoinSuperWorldContentItem 
                                                    borderColor={data.borderColor}
                                                    title={data.title}
                                                    color={(JOIN_SUPER_LIST.length - 1) === index ? '#000' : '#fff'}
                                                    linkList={data.link}
                                                    clickEvent={iconClick}
                                                ></JoinSuperWorldContentItem>
                                            </Content>
                                        </ContentItem>
                                    </ContentItemWrap>
                                </SquareItem>
                                <BtnWrap>
                                    <Btn onClick={()=>BtnClick(data.id)} indexId={data.id}>
                                        <Text lastChk={(JOIN_SUPER_LIST.length - 1) === index}>{data.btnName}</Text>
                                    </Btn>
                                </BtnWrap>
                            </Fade>
                        </InnerWrap>
                    )
                })}
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`

`;

const ContentWrap = styled.div`
    max-width: 950px;
    width: 100%;
    margin: 25px auto 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    @media ${({theme}:any)=>theme.media.s}{
        grid-template-columns: 1fr;
    }
`;

const ContentItemWrap = styled.div`
    width: 100%;
    height: 100%;
    @media ${({theme}:any)=>theme.media.m}{
        padding: 0 15px;
    }
`;

const ContentItem = styled.div<{borderColor?:string}>`
    height: 100%;
    border-radius: 6px;
    border: 1px solid ${props=>props.borderColor ?? 'transparent'};
    position: relative;
    &::before{
        content:'';
        display: block;
        width: 100%;
        height: 100%;
        background-color: ${props=>props.borderColor ?? 'transparent'};
        opacity: 0.2;
    }
`;

const Content = styled.div`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
`;

const BtnWrap = styled(Center)`
    margin: 25px 0 60px;
`;

const Btn = styled(Center)<{indexId:number}>`
    color: #fff;
    border-radius: 30px;
    width: 70%;
    background-color: #362f3c;
    background: linear-gradient(135deg, rgba(37,30,43,1) 0%, rgba(66,60,71,1) 50%, rgba(46,39,51,1) 100%);
    border: 1px solid #ddd;
    font-size: 20px;
    height: 45px;
    cursor: ${props => props.indexId===2 ? 'pointer':'default'};
`;

const Text = styled.div<{lastChk:boolean}>`
    font-size: 22px;
    line-height: 1.5;
    background: ${props=> props.lastChk ? `linear-gradient(135deg, rgba(0,255,212,1) 0%, rgba(0,255,69,1) 50%, rgba(0,255,226,1) 100%)`:`transparent`};
    color: ${props=> props.lastChk ? 'transparent':'#fff'};
    -webkit-background-clip: text;
    font-weight: 900;
`;