import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import { RGBA_ASTC_10x10_Format } from 'three';
import {RootState} from '../store';

const SET_RECENT_TRANSACTION_LIST = 'mesh-dash/transaction/SET_RECENT_TRANSACTION_LIST';
const SET_START_TRANSACTION_LIST = 'mesh-dash/transaction/SET_START_TRANSACTION_LIST';

export const setRecentTransactionActionCreator = createAction<any>(SET_RECENT_TRANSACTION_LIST);
export const setStartTransactionActionCreator = createAction<any>(SET_START_TRANSACTION_LIST);

export function setRecentTransaction(transaction: any): ThunkAction<void, RootState, null, ReturnType<typeof setRecentTransactionActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setRecentTransactionActionCreator({
              transaction
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setStartTransaction(transaction: any): ThunkAction<void, RootState, null, ReturnType<typeof setStartTransactionActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(setStartTransactionActionCreator({
                transaction: transaction
            }));
    } catch (error) {
      console.log(error);
    }
  };
}

const initialState: any = {
  transaction: [],
  transactionCount: []
};

export const recentTransactionList = handleActions<any, any>({
  [SET_START_TRANSACTION_LIST]: (state, action: ReturnType<typeof setStartTransactionActionCreator>) => ({
    transaction: action.payload.transaction ?? []
  }),
  [SET_RECENT_TRANSACTION_LIST]: (state, action: ReturnType<typeof setRecentTransactionActionCreator>) => {
    const prevTranscationList = state.transaction;
    const socketData = action.payload.transaction;
    // prevBlockList.unshift(socketData);
    const result = [socketData, ...prevTranscationList];
    return {
        transaction: result.slice(0, 8),
        transactionCount: result.slice(0, 7)
    }
  }
}, initialState);
