import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import SquareItem from '../../common/item/SquareItem';
import { PLAY_ZONE_LIST } from '../../data/content';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';

export default function PlayZoneSlideItem({list,slideClick}:any) {
    const [dragging, setDragging] = useState(false);
    const {lang} = useGlobalLang();
    
    const handleBeforeChange = useCallback(() => {
        setDragging(true);
    }, [setDragging]);
    
    const handleAfterChange = useCallback(() => {
        setDragging(false);
    }, [setDragging]);

    let settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [ // 반응형 웹 구현 옵션
		{  
			breakpoint: 1200, //화면 사이즈 960px일 때
			settings: {
				//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
				slidesToShow:3 
			} 
		},
		{ 
			breakpoint: 768, //화면 사이즈 768px일 때
			settings: {	
				//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
				slidesToShow:2 
			} 
		}],
        draggable: true,
        beforeChange: handleBeforeChange,
        afterChange: handleAfterChange,
    };

    const itemClick = (link:string) =>{
        if(dragging) return;
        slideClick(link)
    }
    
    return (
        <Wrap>
            <Slider {...settings} >
                {PLAY_ZONE_LIST.map((data)=>{
                    return(
                        <SlideItemWrap key={`paly-slide-${data.id}`} onClick={()=>itemClick(data.link)}>
                            <SlideItem>
                                <img alt='slide-img' src={lang==='en'? data.img_en : data.img}></img>
                            </SlideItem>
                        </SlideItemWrap>
                    )
                })}
                {/* {children} */}
            </Slider>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    .slick-prev{ display:none !important;}
    .slick-next{display:none !important;}
    .slick-dots li button:before{
        content:none;
    }
    .slick-dots li{
        width: 8px;
        height: 8px;
        background-color: #717171;
        border-radius: 25px;
    }
    .slick-dots li.slick-active{
        width: 35px;
        background-color: #ffffff;
    }
`;

const SlideItemWrap = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 5px;
    cursor: pointer;
`;

const SlideItem = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    overflow: hidden;
    & img{
        width: 100%;
    }
`;
